<template>
  <div id="energyFlowChartDialog" ref="energyFlowChartDialog" >
      <!-- :wrap-class-name="wrapClassName" -->
      <!-- wrap-class-name="full-modal" -->
      <!-- :getContainer="() => this.$refs.energyFlowChartDialog" -->
    <a-modal v-model="visible"
      :title="$t('energyFlow.a1')"
      width="100%"
      :footer="null"
    > 
          <div :style="dsty" v-loading="loading" :element-loading-text="$t('alarm.a0')">
              <div :id="energyFlowChartId" :ref="energyFlowChartId" :style="csty"></div>
          </div>
          <div class="zoom">
              <a-button type="primary" shape="circle" icon="zoom-in" @click="zoom(1)" style="margin-right:10px;"></a-button>
              <span>{{scale+'%'}}</span>
              <a-button type="primary" shape="circle" icon="zoom-out" @click="zoom(0)" style="margin-left:10px;"></a-button>
              <a-button type="primary" shape="circle" icon="redo" @click="zoom(2)" style="margin-left:10px;"></a-button>
          </div>
    </a-modal>
  </div>
</template>
<script>
export default {
    name: 'energyFlowChartDialog',
    props: {
        option: {
            type: Object,
            default: undefined,
        }
    }, 
    data() {
        return {
            visible:false,
            loading:false,
            flowChart:undefined,
            dsty:{
              width:'100%',
              height:(window.innerHeight-120)+'px',
              overflow:'auto',
              
            },
            csty:{
              width:'100%',
              height:'800px',
              scale:'100%',
            },
            scale:100,

        };  
    },
    computed: {
        energyFlowChartId() {
            return "energyFlowChartId-" + Math.floor((Math.random() * 10000) + 1);
        },
    },
    watch:{
        "$parent.svisible":{
            handler(val){
                if(val){              
                    this.visible = val;
                    this.loading = true;
                    console.log(this.flowChart);
                   
                    console.log(this.option.series.links.length);
                    if(this.option.series.links.length>100){
                      let len = this.option.series.links.length/100;
                      console.log('len',len);
                      let num = parseInt(len);
                      let hlen = len*800;
                      if(num==4){
                          hlen = 6000;
                      }
                                           
                      console.log('hlen',hlen);
                      this.csty.height = (window.innerHeight+hlen)+'px';
                    }else{
                      this.csty.height = (window.innerHeight-160)+'px';
                    }
                    this.csty.width = (window.innerWidth-50)+'px';

                    setTimeout(()=>{
                        if(this.flowChart){
                          this.flowChart.dispose();
                        }
                        this.drawLine();
                        this.loading = false;
                    },1000);
                    
                }
            },
            deep:true,
            immediate:true,
        },
        "visible":{
            handler(val){
                if(!val){
                    this.$parent.svisible =  val;
                }
            },
            deep:true,
            immediate:true,
        },
    },
    activated(){
        // let resizeDiv = document.getElementById('eneTrendChartImg');
        // console.log('activated resizeDiv',resizeDiv);
    },
    mounted(){
      
    },
    methods: {
      zoom(act){
        if(act==0){
          if(this.scale==10){
            return;
          }
          this.scale -=10;
        } else if(act==1){
          if(this.scale==300){
            return;
          }
          this.scale +=10;
        } else{
          this.scale = 100;
        }

        this.csty.scale = this.scale+"%";
      },
      handleOk(){
          
      },
      handleCancel(){
         this.visible=false;
      },
      
      drawLine() {
          let resizeDiv = document.getElementById(this.energyFlowChartId);
          if(resizeDiv){
            this.trendChart = this.$echarts.init(document.getElementById(this.energyFlowChartId));
            this.option && this.trendChart.setOption(this.option);
            this.trendChart.resize();
          }
      },
    }
  }
</script>
<style scoped>
#energyFlowChartDialog{
  height: 100%;
  width:100%;
  overflow: hidden;
}
div /deep/.ant-modal{
  position: relative;
  top: 20px;
}
div /deep/.ant-modal-body{
    padding: 5px 10px;
}
.zoom{
  position: absolute;
  bottom: 10px;
  right: 5%;
}
</style>