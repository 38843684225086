<!--页面标题组件-->
<template>
  <div id="pageTitle">
    <div class="title">
      <div class="line"></div>
      <slot></slot>
    </div>
    <a-icon type="close" v-if="quit"  @click="returnTo" :style="{ fontSize: '20px', color: '#7682CE' }"/>
  </div>
</template>
<script>
export default {
  props: {
    quit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    returnTo() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
#pageTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 20px;
  letter-spacing: 2px;
  padding: 10px 20px;
  box-shadow: 0px 2px 5px rgba(12, 12, 12, 0.15);
  background-color: #fff;
  .title{
    display: flex;
    align-items: center;
    .line {
    content: "";
    display: inline-block;
    width: 4px;
    height: 30px;
    margin-right: 10px;
    background-color: #7682ce;
  }
  }
}
</style>
