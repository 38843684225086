<!-- 清洁能源流向 -->
<template>
  <div id="cleanEnergyFlows" v-loading="loading" :element-loading-text="$t('cleanEnergyFlows.a1')">
    <div class="pageTitle">
        <my-PageTitle>{{ $t('cleanEnergyFlows.a2') }}</my-PageTitle>
    </div>
    <div class="index">
        <div class="index-header">
            <div class="index-header-condition">
                <!-- 年、月、日选择 -->
                <a-select v-model="yearMonthDaySelect" style="width:80px;margin-right:15px">
                    <a-select-option v-for="(item,index) in rptKinds" :value="item.no" :key="index">
                        {{ item.text }}
                    </a-select-option>
                </a-select>
                <!-- 数据类型选择 -->
                <a-select v-model="dataType" style="width:120px;margin-right:15px">
                    <a-select-option :value="0">
                        {{ $t('cleanEnergyFlows.a3') }}
                    </a-select-option>
                    <a-select-option :value="1">
                        {{ $t('cleanEnergyFlows.a4') }}
                    </a-select-option>
                </a-select>
                <div class="date">
                    <!-- 年条件数据值选择 -->
                    <transition name="slide-fade">
                        <div class="date-select" v-if="yearMonthDaySelect == 1">
                            <a-select v-model="yearDateSelect" style="width: 100px;margin-right:15px">
                                <a-select-option v-for="(item,index) in yearData" :key="index" :value="item.value">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                            <a-checkbox v-model="customDate" style="margin-right:7px">
                                {{ $t('cleanEnergyFlows.a5') }}
                            </a-checkbox>
                            <a-select v-model="monthSelect" style="width: 120px" :disabled="!customDate">
                                <a-select-option v-for="(item,index) in monthList" :value="item.no" :key="index">
                                {{ item.text }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </transition>
                    <!-- 月条件数据值选择 -->
                    <transition name="slide-fade">
                        <div class="date-select" v-if="yearMonthDaySelect == 2">
                            <a-month-picker v-model="monthDateSelect" style="width:140px" :allowClear="false" />
                        </div>
                    </transition>
                    <!-- 日条件数据值选择 -->
                    <transition name="slide-fade">
                        <div class="date-select" v-if="yearMonthDaySelect == 3">
                            <a-date-picker v-model="dayDateSelect" style="width:140px" :allowClear="false" />
                        </div>
                    </transition>
                </div>
            </div>
            <div>
                <a-button type="primary" ghost @click="query">{{ $t('cleanEnergyFlows.a6') }}</a-button>
            </div>
        </div>
        <div class="index-main-empty" v-if="indexShow">
            <a-empty>
                <span class="explain">
                    {{ $t('cleanEnergyFlows.a7') }}
                </span>
            </a-empty>
        </div>
        <div class="index-main" v-else>
            <div class="index-main-table">
                <a-table :columns="columns" :data-source="tableData" :pagination="false">
                </a-table>
            </div>
            <div class="index-main-echarts">
                <div id="cleanEnergyFlowsEcharts" :style="{ width: '100%', height: '100%' }"></div>
                <a-button class="fullscreen" type="primary" icon="fullscreen" @click="showModal" shape="circle"></a-button>
            </div>
        </div>
    </div>
     <my-FlowChart-dialog :option="sankeyOption"></my-FlowChart-dialog>
  </div>
</template>

<script>
import PageTitle from '../../components/Title/PageTitle.vue'
import { getCleanEnergyFlows } from '../../api/energy'
import { EleResize } from '../../utils/esresize'
import energyFlowChartDialog from './components/energyFlowChartDialog'
import moment from 'moment'

export default {
    name:'cleanEnergyFlows',
    components:{
        'my-PageTitle':PageTitle,        
        'my-FlowChart-dialog':energyFlowChartDialog
    },
    data(){
        return{
            loading:false,
            fiscalYear:'',
            yearMonthDaySelect:'1',
            dataType:0,
            monthList:[],
            rptKinds:[],
            yearData:[],
            yearDateSelect:'',
            monthDateSelect:moment(),
            dayDateSelect:moment(),
            customDate:false,
            monthSelect:'',
            indexShow:true,
            columns:[
                {
                    title: this.$t('cleanEnergyFlows.a8'),
                    dataIndex: 'name',
                    align:'center'
                },
                {
                    title: this.$t('cleanEnergyFlows.a9'),
                    dataIndex: 'value',
                    align:'center'
                }
            ],
            tableData:[],
            sankeyOption:{
                tooltip: {
                    trigger: "item",
                    triggerOn: "mousemove",
                },
                series: {
                    type: 'sankey',
                    layout: 'none',
                    emphasis: {
                        focus: 'adjacency'
                    },
                    data: [],
                    links: []
                }
            },
            echarts:undefined,
            svisible:false,
        }
    },
    // 清洁能源流向页面初始化数据
    mounted(){
         this.loading = true
        const data = {
            action:0,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId
        }
        getCleanEnergyFlows(data)
        .then(res=>{
            console.log(res)
            const { data:{ fiscalYear,monthList,rptKinds,yearData }} = res
            this.fiscalYear = fiscalYear
            this.yearDateSelect = fiscalYear
            this.monthList = monthList
            this.monthSelect = monthList[0].no
            this.rptKinds = rptKinds
            this.yearData = yearData
            this.loading = false
        })
        .catch(err=>{
            console.log(err)
            this.loading = false
        })
    },
    methods:{
        moment,
        // 清洁能源流向页面数据获取
        query(){
            this.loading = true
            const clientId = this.$store.getters.clientId
            const sitegrId = this.$store.getters.sitegrId
            const reportType = this.yearMonthDaySelect
            const carbon = this.dataType
            const year = this.yearDateSelect
            const defaultStatistic = this.customDate ? 1 : 0
            const month = this.monthSelect
            const monthDateSelect = this.monthDateSelect.format('YYYY/MM')
            const dayDateSelect = this.dayDateSelect.format('YYYY/MM/DD')
            const data = {
                action:1,
                clientId,
                sitegrId,
                reportType,
                carbon,
            }
            if(reportType == '1'){
                data.year = year
                data.defaultStatistic = defaultStatistic
                if(defaultStatistic == 1){ data.month = month}
            }else if(reportType == '2'){
                data.date = monthDateSelect
            }else{
                data.date = dayDateSelect
            }
            console.log(data)
            getCleanEnergyFlows(data)
            .then(res=>{
                console.log(res)
                this.indexShow = false
                const { data:{ directionData:{data,links,subtext,text},tableData }} = res
                this.tableData = tableData
                this.sankeyOption.series.data = data
                this.sankeyOption.series.links = links
                if(carbon == 0){
                    this.columns[1].title = `${this.$t('cleanEnergyFlows.a3')}(${subtext})`
                }else{
                    this.columns[1].title = `${this.$t('cleanEnergyFlows.a9')}(${subtext})`
                }
                this.$nextTick(function () {
                        if(this.echarts !== undefined){ this.echarts.dispose() }
                        this.drawPie1()
                    })
                this.loading = false
            })
            .catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
        // 绘画清洁能源流向图
         drawPie1() {
          let myChart = this.$echarts.init(document.getElementById('cleanEnergyFlowsEcharts'))
          let resizeDiv = document.getElementById('cleanEnergyFlowsEcharts')
          this.echarts = myChart
          myChart.setOption(this.sankeyOption)
          let listener = () => {
              myChart.resize()
          }
          EleResize.on(resizeDiv, listener)
        },
        showModal(){
            this.svisible = true;      
        },
    }
}
</script>

<style scoped>
#cleanEnergyFlows{
    width: 100%;
    height: 100%;
}
.index{
    width: 100%;
    height: calc(100% - 50px);
    padding: 20px;
}
.index-header{
    display: flex;
    justify-content: space-between;
}
.index-header-condition{
    display: flex;
}
.date{
    position: relative;
}
.date-select{
    width: 400px;
    position: absolute;
}
.index-main-empty{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
   height: calc(100% - 32px);
}
.explain{
    font-size: 20px;
    color: #7682ce;
}
.index-main{
    display: flex;
    width: 100%;
    height: calc(100% - 32px);
    padding-top: 10px;
}
.fullscreen{
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.index-main-table{    
    width: 350px;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    overflow: auto;
}
.index-main-echarts{
    width: calc(100% - 350px);
    height: 100%;
    position: relative;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>